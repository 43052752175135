.dashboard img {
  margin-top: 100px;
  width: 300px;
}

.dashboard h4 {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.dashboard p {
  font-size: 15px;
  margin-top: 20px;
  text-align: center;
  color: #aaa;
}
