.sidebar {
  color: #848484;
}
.sidebar .blank-area {
  width: calc(100% - 250px);
  min-height: 100vh;
  position: fixed;
  right: 0px;
  background-color: #000;
  opacity: 0.5;
  z-index: 2;
}

.sidebar .container {
  max-width: 250px;
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  position: absolute;
  left: 0px;
  background-color: #fff;
  z-index: 2;
}

.sidebar .container img {
  margin: 20px 25px 20px 25px;
  padding: 0px;
  width: 200px;
  height: 200px;
  float: left;
}

.sidebar .container h4,
.sidebar .container h6 {
  margin-left: 20px;
}
.sidebar .container ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.sidebar .container ul li {
  width: 100%;
  padding: 15px 10px 15px 20px;
}

.sidebar .container p {
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #aaa;
}
