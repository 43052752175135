.login img {
  width: 300px;
}

.login button {
  border: 0px;
  border-radius: 25px;
  padding: 13px 50px 13px 50px;
  display: block;
  margin: 0px auto;
  margin-top: 25px;
  background-color: #3e45f7;
  color: #fff;
}

.login p {
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}
