header {
  width: 100%;
  height: 60px;
  color: #95a5a6;
  box-shadow: 0px 0px 7px #444;
  float: left;
}

header button {
  margin-left: 5%;
  margin-top: 12px;
  float: left;
}

header h5 {
  float: left;
  line-height: 60px;
  margin-left: 10px;
}

header input {
  width: 350px;
  padding: 8px;
  padding-left: 15px;
  margin-top: 10px;
  margin-left: 15px;
  border: 0px;
  background-color: #eaeded;
  float: left;
}

header nav {
  float: right;
  background-color: #fff;
  font-size: 15px;
  margin-right: 5%;
  z-index: 1;
}

header ul {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style: none;
  float: left;
}
header li {
  margin-left: 20px;
  width: 80px;
  height: 60px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

header li:hover,
header .active {
  border-bottom: 2px solid #2e86c1;
  color: #2e86c1;
}

/* Mobile Screens */

@media only screen and (max-width: 1100px) {
  header nav {
    width: 100%;
    height: 50px;
    margin: 0px;
    margin-top: -50px;
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 7px #95a5a6;
  }
  header ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  header ul li {
    width: auto;
    margin: 0px;
    margin-top: 15px;
    font-size: 18px;
    height: 20px;
  }

  header button {
    margin: 0px;
    margin-top: 13px;
    margin-left: 5%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  header input {
    width: 90%;
    margin: 7px 5% 0px 5%;
    padding: 12px;
    padding-left: 40px;
    background-color: #eee;
  }

  header .hide-on-mobile {
    display: none;
  }
}
